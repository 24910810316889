import React, { useState, useRef } from 'react'
import Fuse from "fuse.js"
import axios from 'axios';
import { airports } from '../static/airportCodes'
export default function TravelForm() {
  const API_PATH = 'https://www.bonvoyageairways.com/form/travel.php';
  const formEl: any = useRef()
  const counterRef = useRef(0);
  const [airportData, setAirportData] = useState<any>([])
  const [flightModal, setFlightModal] = useState<boolean | null>(false)
  const [flightType, setFlightType] = useState<string>("oneway")
  async function formSubmit(event: any) {
    event.preventDefault();
    setFlightModal(true)
    var formData: any = []
    counterRef.current += 1;
    if (counterRef.current % 2 !== 0) {
      const formInputs = [...formEl.current.elements]
      const newSubmitted = formInputs.reduce(
        (acc, input) => {
          return {
            ...acc,
            [input.name]: input.value
          };
        },
        { number: counterRef.current }
      );
      formData = [...formData, newSubmitted]
      console.log(formData[0])
      await sendData(formData[0])

    }
  }
  async function sendData(data: any) {
    let fData = new FormData()
    fData.append("name", data.name)
    fData.append("phoneNumber", data.phoneNumber)
    fData.append("departure", data.departure)
    fData.append("destination", data.destination)
    fData.append("travelDate", data.travelDate)
    fData.append("returnDate", data.returnDate ? data.returnDate : "")
    fData.append("travellers", data.travellers)
    fData.append("flightClass", data.flightClass)
    fData.append("airline", data.airline)

    await axios({
      method: 'post',
      url: `${API_PATH}`,
      data: fData
    })
      .then(result => {
        console.log('sss')
        console.log(result)
      })
      .catch(error => console.log(error));
  }
  function fuseData(input: any) {
    const options = {
      shouldSort: true,
      threshold: 0.2,
      maxPatternLength: 32,
      keys: [{
        name: "IATA",
        weight: 0.6
      },
      {
        name: "country",
        weight: 0.2
      },
      {
        name: "city",
        weight: 0.1
      }
      ]
    }
    const fuse = new Fuse(airports, options)
    const search = fuse.search(input)
    return search
  }
  function handleAirport(e: any) {
    e.preventDefault()
    var allInputs: any = document.querySelector(".airport-input")?.nextSibling
    allInputs?.classList.remove("show")
    e.target.nextSibling?.classList.add("show")
    if (e.target.value.length >= 2) {
      var data = fuseData(e.target.value)
      setAirportData(data)
    } else {
      setAirportData([])
    }

  }
  function addAirportName(e: MouseEvent | any, inputName: string) {
    console.log(e.target.innerHTML)
    var input: any = document.getElementById(inputName)
    if (input) {
      input.value = e.target.innerHTML
    }
    setAirportData([])
  }
  function closeFlightLoader() {
    setFlightModal(false)
  }
  return (
    <>
      <form className='flight-search' ref={formEl} onSubmit={formSubmit}>
        <div className="flight-search-wrapper">
          <h3>Search Flights</h3>
        </div>
        <div className="form-group radio-wrapper">
          <div>
            <input type="radio" name="travelType" id="" value="oneway" defaultChecked onClick={(e) => setFlightType("oneway")} />
            <label htmlFor="">One Way</label>
          </div>
          <div>
            <input type="radio" name="travelType" id="" value="roundTrip" onClick={(e) => setFlightType("roundTrip")} />
            <label htmlFor="">Round Trip</label>
          </div>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="">Name *</label>
          <input type="text" id="name" name='name' placeholder='Name' required />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="">Phone Number *</label>
          <input type="text" id="phoneNumber" name='phoneNumber' placeholder='Phone Number' required />
        </div>
        <div className="form-group">
          <label htmlFor="">From *</label>
          <input type="text" name='departure' className="airport-input" id="departureCity" placeholder='Enter Departure City' required onChange={e => handleAirport(e)} onKeyUp={e => handleAirport(e)} onFocus={e => handleAirport(e)} />
          {airportData.length > 0 && (
            <div className="airport-dropdown">
              {airportData.slice(0, 5).map((data: any, i: number) => (
                <div className="airport-list" key={i} onClick={(e) => addAirportName(e, "departureCity")}>
                  {data.item.city} - {data.item.IATA}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="">To *</label>
          <input type="text" name='destination' className="airport-input" id="destination" placeholder='Enter Destination' required onChange={e => handleAirport(e)} onKeyUp={e => handleAirport(e)} onFocus={e => handleAirport(e)} />
          {airportData.length > 0 && (
            <div className="airport-dropdown">
              {airportData.slice(0, 5).map((data: any, i: number) => (
                <div className="airport-list" key={i} onClick={(e) => addAirportName(e, "destination")}>
                  {data.item.city} - {data.item.IATA}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="">Departure *</label>
          <input type="date" id="date" placeholder='Enter Date' required name='travelDate' />
        </div>
        {flightType == "roundTrip" && (
          <div className="form-group">
            <label htmlFor="">Return *</label>
            <input type="date" id="returnDate" placeholder='Return Date' required name='returnDate' />
          </div>
        )}
        <div className="form-group">
          <label htmlFor="">Travellers *</label>
          <input type="number" id="travellers" placeholder='3' required name='travellers' />
        </div>
        <div className="form-group">
          <label htmlFor="">Class *</label>
          <select name="flightClass" id="">
            <option value="Economy" defaultValue={"selected"}>Economy</option>
            <option value="Premium Economy">Premium Economy</option>
            <option value="Business">Business</option>
            <option value="First Class">First Class</option>
          </select>
        </div>
        <div className="form-group w-75">
          <label htmlFor="">Airlines *</label>
          <select id="airline" name="airline">
            <option defaultValue={"selected"} value="">Any Airline</option>
            <option value="JP">Adria Airways</option>
            <option value="EI">Aerlingus</option>
            <option value="SU">Aeroflot</option>
            <option value="AR">Aerolineas Argen </option>
            <option value="AM">Aeromexico</option>
            <option value="5L">Aerosur </option>
            <option value="VV">Aerosvit</option>
            <option value="8U">Afriqiyah Airline</option>
            <option value="AH">Air Algerie</option>
            <option value="KC">Air Astana</option>
            <option value="BT">Air Baltic</option>
            <option value="AB">Air Berlin</option>
            <option value="2J">Air Burkina </option>
            <option value="AC">Air Canada</option>
            <option value="CA">Air China </option>
            <option value="A7">Air Comet </option>
            <option value="UX">Air Europa </option>
            <option value="AF">Air France </option>
            <option value="AI">Air India </option>
            <option value="JM">Air Jamaica</option>
            <option value="KM">Air Malta </option>
            <option value="MK">Air Mauritius </option>
            <option value="9U">Air Maldova</option>
            <option value="SW">Air Namibia </option>
            <option value="NZ">Air New Zealand </option>
            <option value="HM">Air Seychelles </option>
            <option value="VT">Air Tahiti </option>
            <option value="TN">Air Tahiti Nui </option>
            <option value="TS">Air Transat</option>
            <option value="UM">Air Zimbabwe</option>
            <option value="AS">Alaska Air</option>
            <option value="LV">Albanian Air</option>
            <option value="AZ">Alitalia </option>
            <option value="NH">All Nippon </option>
            <option value="AA">American Air</option>
            <option value="OZ">Asiana Airlines</option>
            <option value="GR">Aurigny</option>
            <option value="OS">Austrian Airline </option>
            <option value="AV">Avianca </option>
            <option value="J2">Azerbaijan</option>
            <option value="UP">Bahamasair</option>
            <option value="PG">Bangkok Airways </option>
            <option value="BG">Biman Bangla</option>
            <option value="BD">Bmi British </option>
            <option value="BA">British Airways</option>
            <option value="SN">Brussels Airline </option>
            <option value="FB">Bulgaria Air</option>
            <option value="BW">Caribbean Air</option>
            <option value="CX">Cathay Pacific </option>
            <option value="CI">China Airlines </option>
            <option value="MU">China Eastern </option>
            <option value="CZ">China Southern </option>
            <option value="QI">Cimber Sterli</option>
            <option value="CF">City Airline</option>
            <option value="DE">Condor </option>
            <option value="CO">Continental </option>
            <option value="OU">Croatia Air </option>
            <option value="CU">Cubana Airlines </option>
            <option value="CY">Cyprus Airways </option>
            <option value="OK">Czech Airlines </option>
            <option value="D3">Daallo</option>
            <option value="DL">Delta </option>
            <option value="T3">Eastern airways</option>
            <option value="MS">Egyptair </option>
            <option value="EK">Emirates Air </option>
            <option value="OV">Estonian Air</option>
            <option value="ET">Ethiopian Air </option>
            <option value="EY">Etihad Airways </option>
            <option value="BR">EVA Airways </option>
            <option value="AY">Finnair </option>
            <option value="BE">Flybe </option>
            <option value="GA">Garuda </option>
            <option value="GF">Gulf Air </option>
            <option value="HR">Hahn Air </option>
            <option value="HU">Hainan Airlin</option>
            <option value="YO">Heli-Air Monaco</option>
            <option value="EO">Hewa Bora Air </option>
            <option value="IB">Iberia </option>
            <option value="FI">Iceland Air </option>
            <option value="IC">Indian Air </option>
            <option value="IR">Iran Air </option>
            <option value="6H">Israir</option>
            <option value="JL">Japan Airline</option>
            <option value="JU">Jat Airways</option>
            <option value="9W">Jet Airways </option>
            <option value="KQ">Kenya Airways </option>
            <option value="KL">KLM </option>
            <option value="KE">Korean Air </option>
            <option value="KU">Kuwait Airways </option>
            <option value="LA">Lan Airlines </option>
            <option value="LO">Lot-Polish </option>
            <option value="LH">Lufthansa </option>
            <option value="CC">Macair</option>
            <option value="W5">Mahan Air </option>
            <option value="MA">Malev </option>
            <option value="MH">Malaysia </option>
            <option value="MP">Martinair </option>
            <option value="IG">Meridiana </option>
            <option value="MX">Mexicana </option>
            <option value="ME">Middle East</option>
            <option value="YM">Montenegro</option>
            <option value="CE">Nationwide Air </option>
            <option value="NW">Northwest </option>
            <option value="OA">Olympic </option>
            <option value="WY">Oman Aviation </option>
            <option value="PR">Philippine </option>
            <option value="QR">Qatar Airways </option>
            <option value="AT">Royal Air Maroc </option>
            <option value="BI">Royal Brunei </option>
            <option value="RJ">Royal Jordanian </option>
            <option value="FV">Rossiya </option>
            <option value="SK">Sas </option>
            <option value="S4">Sata Intl </option>
            <option value="SV">SAUDi Arabian </option>
            <option value="S7">Siberia Air</option>
            <option value="SQ">Singapore Airlines</option>
            <option value="SA">South African </option>
            <option value="UL">SriLankan </option>
            <option value="SD">Sudan </option>
            <option value="LX">Swiss </option>
            <option value="RB">Syrian Arab</option>
            <option value="DT">Taag-Angola </option>
            <option value="VR">Tacv Carbo Verdes </option>
            <option value="JJ">Tam Linhas Aerea </option>
            <option value="TP">Tap Portugal Airline</option>
            <option value="TG">Thai Intl </option>
            <option value="UN">Transaero</option>
            <option value="TU">Tunis Air</option>
            <option value="TK">Turkish Airlines </option>
            <option value="QF">Qantas Flights </option>
            <option value="PS">Ukraine Intl </option>
            <option value="UA">United</option>
            <option value="US">US Airways </option>
            <option value="HY">Uzbekistan </option>
            <option value="VN">Vietnam Air </option>
            <option value="VS">Virgin Atlantic </option>
            <option value="VK">Virgin Nigeria </option>
            <option value="WM">Windward Island</option>
            <option value="IY">Yemenia Yemen </option>
          </select>
        </div>
        <div className="form-group submit">
          <input type="submit" value="Search Flights" />
        </div>
      </form>
      {flightModal && (
        <div className='flight-loader'>
          <div className="loader-wrapper">
            <div className="close-loader" onClick={closeFlightLoader}>✕</div>
            <div className="container">
              <div className="row">
                <div className="loader-info">
                  <h3>We’re Finding The Best Flights For You</h3>
                  <h4>Please Wait, Your Results Will Be Displayed Shortly!</h4>
                </div>
                <img src={"./images/loadingPlane.gif"} alt="" />
                <div className="contact-info">
                  <h3>For any Assistance Call us</h3>
                  <a href="tel://225-245-7946" className="call-us-loader">225-245-7946</a>
                </div>
              </div>
            </div>
          </div>

        </div>
      )}
    </>
  )
}
