import React from 'react'

export default function Features() {
    return (
        <>
            <section id='features'>
                <div className="container">
                    <div className="row gap-20">
                        <div className="col-md-12">
                            <div className="heading">
                                <h3>Our Features</h3>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="feature-card">
                                <div className="feature-image">
                                    <img src={"../images/airplane.png"} alt="" />
                                </div>
                                <div className="feature-text">
                                    <h4>Flight Booking</h4>
                                    <p>Book your flight with our agent and avail extra discount!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="feature-card">
                                <div className="feature-image">
                                    <img src={"../images/hotel.png"} alt="" />
                                </div>
                                <div className="feature-text">
                                    <h4>Top Class Hotels</h4>
                                    <p>Our agents helps you book the best hotels all around the world!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="feature-card">
                                <div className="feature-image">
                                    <img src={"../images/field-trip.png"} alt="" />
                                </div>
                                <div className="feature-text">
                                    <h4>Amazing Tour Packages</h4>
                                    <p>Must use our tour Planner for breathtaking tour packages!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
