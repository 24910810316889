import React, { useRef, useState, useEffect } from 'react'
import axios from 'axios';
import EnquirySuccess from './EnquirySuccess';
const API_PATH = 'https://www.bonvoyageairways.com/form/index.php';

export default function Modal({ name, setShowModal }: any) {
    const formEl: any = useRef()
    const counterRef = useRef(0);
    const [submitted, setSubmitted] = useState<any | null>([]);
    const [enquiryModal, setEnquiryModal] = useState<boolean | null>(false)

    async function formSubmit(event: any) {
        event.preventDefault();
        var formData: any = []
        counterRef.current += 1;
        if (counterRef.current % 2 !== 0) {
            const formInputs = [...formEl.current.elements]
            const newSubmitted = formInputs.reduce(
                (acc, input) => {
                    return {
                        ...acc,
                        [input.name]: input.value
                    };
                },
                { number: counterRef.current }
            );
            formData = [...formData, newSubmitted]
            setSubmitted((prevSubmitted: any) => [...prevSubmitted, newSubmitted]);
            console.log("sss")
            await sendData(formData[0])

        }
    }
    async function sendData(data: any) {
        let fData = new FormData()
        fData.append("fullName", data.fullName)
        fData.append("email", data.email)
        fData.append("fromCity", data.fromCity)
        fData.append("fromDate", data.fromDate)
        fData.append("toCity", data.toCity)
        fData.append("toDate", data.toDate)
        fData.append("number", data.number)
        fData.append("travellers", data.travellers)
        fData.append("extraNotes", data.extraNotes ? data.extraNotes : "")

        await axios({
            method: 'post',
            url: `${API_PATH}`,
            data: fData
        })
            .then(result => {
                setSubmitted([])
                console.log('sss')
                setEnquiryModal(true)

                console.log(result)
            })
            .catch(error => console.log(error));
    }
    return (
        <>
            <div className='modal-wrapper'>
                <div className="modal">
                    <div className="close-modal" onClick={(e) => setShowModal(null)}>
                        ✕
                    </div>
                    {enquiryModal ? (
                        <EnquirySuccess />
                    ) : (
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="heading">
                                        <h3>Plan Your Dream {name} Holiday</h3>
                                    </div>
                                    <div className="modal-form">
                                        <form ref={formEl} onSubmit={formSubmit}>
                                            <div className="form-group full-width">
                                                <label htmlFor="">Full Name *</label>
                                                <input name="fullName" type="text" required id='fullName' placeholder='Enter Your Full Name' />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="">Email Id *</label>
                                                <input name="email" type="email" id="email" placeholder='Enter Email Address' required />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="">Phone Number *</label>
                                                <input name="number" type="text" id="phoneNumber" placeholder='Enter Phone Number' required />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="">Departure City *</label>
                                                <input name='fromCity' type="text" id="departureCity" placeholder='Enter Departure City' required />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="">Where do you want to go? *</label>
                                                <input name="toCity" type="text" id="destination" placeholder='Enter Destination' defaultValue={name} required />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="">Travel Date *</label>
                                                <input name="fromDate" type="date" id="date" placeholder='Enter Date' required />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="">Return Date *</label>
                                                <input name="toDate" type="date" id="returnDate" placeholder='Return Date' required />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="">Number of Travellers *</label>
                                                <input name="travellers" type="number" id="travellers" placeholder='3' required />
                                            </div>
                                            <div className="form-group text-area">
                                                <label htmlFor="">Any special notes or requests?</label>
                                                <textarea name="extraNotes" id="" placeholder='Eg, Special occasion, must-do"s or add-ons'></textarea>
                                            </div>
                                            <div className="form-group submit">
                                                <input name="submit" type="submit" value="Submit" />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </>

    )
}
