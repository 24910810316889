import React from 'react'

export default function EnquirySuccess() {
    return (
        <div className='enquiry-wrapper'>
            <div className="enquiry">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3>Thank you for contacting Us</h3>
                            <p>We will get back to you shortly</p>

                        </div>
                    </div>
                    <div className="modal-bottom">
                        <p>Talk to Our Agent to Avail extra discount (Toll Free)</p>
                        <a href="tel://225-245-7946" className='talk-agent'><span>Call Now</span></a>
                    </div>
                </div>
            </div>
        </div>
    )
}
