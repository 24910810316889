import React from 'react'

export default function HowItWorks() {
  return (
    <section className='light-bg'>
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <div className="heading">
                    <h3>How it Works</h3>
                </div>
                <div className="text-center">
                <p>Our specialist have all travelled extensively in their regions and they return each year to learn more to find new different ways for you to experience your destination. That means that we can arrange immersive tours and off-the-beaten-path activities to help you get at the authentic soul of a place. We also work with the best local guides, some of whom are long-time friends of Bon Voyage, and can suggest the most interesting hotels, homestays and lodges.</p>
                <p>Our experts listen to your interests, share their advice, and then handcraft a personalised itinerary based on what you want and nothing more. They'll work with you until you’re satisfied with every detail. We’ll then manage all your reservations and logistics so that you’re taken care of from the moment you step off the plane until your return. Whether you choose to include guides throughout or set off on a more independent trip, you can travel reassured, knowing that we have more than 20 years of experience and provide you with around-the-clock support to smooth out the unexpected.</p>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}
