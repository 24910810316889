import React, { useState } from 'react'
export default function BottomPop() {
  const [showBottom, setShowBottom] = useState(true)
  function hideBottom(){
    setShowBottom(false)
  }
  return (
    <>
      {showBottom && (
        <div className='bottom-pop'>
          <div className="container">
            <div className="row align-center">
              <div className="col-md-8">
                <div className="bottom-wrapper row align-center">
                  <div className="avatar">
                    <img src={"./images/avatar.jpg"} alt="" />
                  </div>
                  <div className="bottom-content">
                    <h4>Looking for last-minute deals? Just give us a call to get phone-only deals! <span style={{color: "var(--accent-color)", fontSize: "1.1em"}}>(Upto 15% Discount)</span></h4>
                    <p>Call us at <a href="tel:225-245-7946">225-245-7946</a> and get assistance 24/7.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 text-right">
                <button onClick={hideBottom}>╳</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
