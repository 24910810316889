import React from 'react'

export default function Footer() {
    return (
        <>
            <footer>
                <div className="container">
                    <div className="row align-center">
                        <div className="col-md-4">
                            <div className="footer-logo"><img src={"../images/logo.png"} alt="" /></div>
                        </div>
                        <div className="col-md-8">
                            <div className="footer-details text-right">
                                <h5>Avail Extra Discount on your flights & trips</h5>
                                <h6>Contact Us</h6>
                                <p style={{margin: "15px 0"}}>30126 WH Rhodus Road,Holden,Louisiana 70744</p>
                                <a href="tel:225-245-7946">225-245-7946</a>
                                <a href="mailto:contact@bonvoyageairways.com">contact@bonvoyageairways.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="footer-copyright text-center dark-bg">
                © {new Date().getFullYear()} | Bon Voyage | All Rights Reserved
            </div>
        </>
    )
}
