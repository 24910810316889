import React from 'react'

export default function About() {
  return (
    <section className='light-bg' id='about'>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="heading">
                        <h3>About Us</h3>
                    </div>
                    <div className="text-center">
                    <p>Bon Voyage is US based travel company that comes intending to give its Travller a joyful booking
experience. We Cruised into the travel industry to make your travel Salubrious.
We we work in liaison with the leading airlines around the world to bring you the holidays of your dreams.
We are a team of dedicated travel professionals who work round-the-clock to give you expert assistance
at minimum charges.</p>
                    <p>Your Travel spirit will be following you from the cloudy heavens to the steep rocks in the himalyas.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
