import { useState } from "react"

export default function Header() {
    const [showMobileMenu, setShowMobileMenu] = useState(false)
    function toggleMenu() {
        if (showMobileMenu) {
            setShowMobileMenu(false)
        } else {
            setShowMobileMenu(true)
        }
    }
    return (
        <>
        <div className="header-top">
            <div className="container">
                <p>Call Us: <a href="tel:225-245-7946">225-245-7946</a></p>
                
            </div>
        </div>
        <header className={showMobileMenu ? "open-menu" : ""}>
            <div className="container">
                <div className="row align-center j-sb">
                    <div className="col-md-3">
                        <div className="logo">
                            <img src={"../images/logo.png"} alt="" />
                        </div>
                    </div>
                    <div className="col-md-9 text-right row j-fe">
                        <div className="links">
                            <ul>
                                <li><a href="#top">Home</a></li>
                                <li><a href="#features">Features</a></li>
                                <li><a href="#tours">Tours</a></li>
                                <li><a href="#tours">Flights</a></li>
                                <li className="dropdown"><a href="#tours">Top Destinations</a>
                                <ul className="submenu">
                                        <li><a href="#">Boston</a></li>
                                        <li><a href="#">Dallas</a></li>
                                        <li><a href="#">LOS ANGELES</a></li>
                                        <li><a href="#">SAN DIEGO</a></li>
                                        <li><a href="#">PARIS</a></li>
                                        <li><a href="#">SYDNEY</a></li>
                                        <li><a href="#">SAN JOSE</a></li>
                                        <li><a href="#">CANCUN</a></li>
                                        <li><a href="#">LONDON</a></li>
                                        <li><a href="#">ATLANTA</a></li>
                                    </ul>
                                </li>
                                <li className="dropdown"><a href="#tours">Top Airlines</a>
                                <ul className="submenu">
                                        <li><a href="#">Air Canada</a></li>
                                        <li><a href="#">Spirit</a></li>
                                        <li><a href="#">Southwest</a></li>
                                        <li><a href="#">United</a></li>
                                        <li><a href="#">Delta</a></li>
                                        <li><a href="#">Frontier</a></li>
                                        <li><a href="#">Copa</a></li>
                                        <li><a href="#">Aermexico</a></li>
                                        <li><a href="#">Allegiant</a></li>
                                        <li><a href="#">Alaska</a></li>
                                    </ul>
                                </li>
                                <li><a href="#about">About</a></li>

                            </ul>
                        </div>
                        <div className="tel-header">
                            <a href="tel:225-245-7946" className="btn">
                                <span>Book Now</span>
                            </a>
                        </div>
                    </div>
                    <div className="hamburger-menu-wrapper">
                        <div className="menu-icon" onClick={toggleMenu}><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div className="mobile-menu">
                            <ul>
                                <li><a href="#top" onClick={toggleMenu}>Home</a></li>
                                <li><a href="#features" onClick={toggleMenu}>Features</a></li>
                                <li><a href="#tours" onClick={toggleMenu}>Tours</a></li>
                                <li><a href="#" onClick={toggleMenu}>Top Destinations</a></li>
                                <li><a href="#" onClick={toggleMenu}>Top Airlines</a></li>
                                <li><a href="#about" onClick={toggleMenu}>About</a></li>

                            </ul>
            </div>
        </header>
        </>
    )
}
