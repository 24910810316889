import React, { useEffect, useState } from 'react'
import About from '../components/About'
import BottomPop from '../components/BottomPop'
import Features from '../components/Features'
import Footer from '../components/Footer'
import Header from '../components/Header'
import HowItWorks from '../components/HowItWorks'
import Tours from '../components/Tours'
import TravelForm from '../components/TravelForm'
export default function Homepage() {
  return (
    <>
      <Header />
      <main id='top'>
        <section className="hero light-bg">
          <div className="container">
            <div className="row align-center j-sb header-form">
              <TravelForm/>
            </div>
          </div>
        </section>
        <Features />
        <HowItWorks />
        <Tours />
        <About />
      </main>
      <Footer />
      <BottomPop />
    </>
  )
}
