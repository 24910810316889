import React, {useState} from 'react'
import Modal from './Modal'
export default function Tours() {
    const [showModal, setShowModal] = useState<string | null>(null)
    function openModal(event: any, destination: string){
        event.preventDefault()
        setShowModal(destination)
        
    }
  return (
    <>
        <section id='tours'>
        <div className="container">
            <div className="row gap-20">
                <div className="col-md-12 mb-40">
                    <div className="heading">
                        <h3>Top Holiday Deals</h3>
                    </div>
                    <p className='text-center'>* Fares shown are round trip. Fares include fuel surcharges, our service Taxes & Fees. The fares on the website are subject to change with respect to any change in the itinerary or guidelines of the airlines without any notice.</p>
                </div>
                <div className="col-md-4">
                    <div className="tour-wrapper">
                        <div onClick={(e) => openModal(e, "Dubai")}>
                        <div className="tour-details">
                            <div className="tour-title">Dubai</div>
                            <div className="text-right"><span className='starting-from'>Starting From</span><span className='price'>$499</span></div>
                        </div>
                        <div className="tour-img-wrapper">
                            <img src={"./images/dubai.jpg"} alt="" />
                            <div className="hover-btn">
                                <p className='btn'>Book Now</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="tour-wrapper">
                        <div onClick={(e) => openModal(e, "Bangkok")}>
                        <div className="tour-details">
                            <div className="tour-title">Bangkok</div>
                            <div className="text-right"><span className='starting-from'>Starting From</span><span className='price'>$589</span></div>
                        </div>
                        <div className="tour-img-wrapper">
                            <img src={"./images/bangkok.jpg"} alt="" />
                            <div className="hover-btn">
                                <p className='btn'>Book Now</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="tour-wrapper">
                    <div onClick={(e) => openModal(e, "Miami")}>
                        <div className="tour-details">
                            <div className="tour-title">Miami</div>
                            <div className="text-right"><span className='starting-from'>Starting From</span><span className='price'>$788</span></div>
                        </div>
                        <div className="tour-img-wrapper">
                            <img src={"./images/miami.jpg"} alt="" />
                            <div className="hover-btn">
                                <p className='btn'>Book Now</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="tour-wrapper">
                    <div onClick={(e) => openModal(e, "Toronto")}>
                        <div className="tour-details">
                            <div className="tour-title">Toronto</div>
                            <div className="text-right"><span className='starting-from'>Starting From</span><span className='price'>$886</span></div>
                        </div>
                        <div className="tour-img-wrapper">
                            <img src={"./images/toronto.webp"} alt="" />
                            <div className="hover-btn">
                                <p className='btn'>Book Now</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="tour-wrapper">
                    <div onClick={(e) => openModal(e, "New York")}>
                        <div className="tour-details">
                            <div className="tour-title">New York</div>
                            <div className="text-right"><span className='starting-from'>Starting From</span><span className='price'>$1050</span></div>
                        </div>
                        <div className="tour-img-wrapper">
                            <img src={"./images/newyork.jpg"} alt="" />
                            <div className="hover-btn">
                                <p className='btn'>Book Now</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="tour-wrapper">
                    <div onClick={(e) => openModal(e, "Orlando")}>
                        <div className="tour-details">
                            <div className="tour-title">Orlando</div>
                            <div className="text-right"><span className='starting-from'>Starting From</span><span className='price'>$1089</span></div>
                        </div>
                        <div className="tour-img-wrapper">
                            <img src={"./images/orlando.jpg"} alt="" />
                            <div className="hover-btn">
                                <p className='btn'>Book Now</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {showModal && (
        <Modal name={showModal} setShowModal={setShowModal}/>
    )}
    </>
  )
}
